import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

const HeartlessPage = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout location={location}>
      <SEO title="Blog – All Posts" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article className="post-content" key={node.fields.slug}>
            <header>
              <h2 className="container" style={{ paddingBottom: "0" }}>
                <Link to={node.fields.slug}>{title}</Link>
              </h2>
              <h5 className="date container">{node.frontmatter.date}</h5>
            </header>
            <section
              className="container"
              dangerouslySetInnerHTML={{
                __html: node.html,
              }}
            ></section>
          </article>
        )
      })}
    </Layout>
  )
}

export default HeartlessPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
